body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
  height: "100%";
  width: auto !important;
  overflow-x: hidden !important;
  overflow-y: scroll;
  cursor: pointer;
}

@media screen and (min-width: 800px) {
  body {
    padding-top: 94px;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .form-control {
    font-size: 16px;
  }
}

.form-control:focus {
  border-color: #ccc;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.html {
  height: 100% !important;
}

.body {
  height: 100% !important;
}

.videoDisplay::-webkit-scrollbar {
  display: none;
}

.videoDisplay {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#basic-navbar-nav:focus {
  z-index: 10;
  border-color: #ffffff !important;
  outline: 0 !important;
  background-color: #232f3e !important;
}

#basic-nav-dropdown {
  color: white;
  font-weight: bold;
  background-color: #232f3e;
}

.active.page-link {
  background: #232f3e !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.VideoInput {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  margin-bottom: 100px;
}

.VideoInput_input {
  display: none;
}

.VideoInput_video {
  display: block;
  margin: 0;
}

.VideoInput_footer {
  overflow-wrap: break-word;
  background: #eee;
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
}

form-control:focus {
  border-color: #ccc;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

textarea {
  /* iOS Safari */
  -webkit-touch-callout: auto;
  /* Safari */
  -webkit-user-select: auto;
  /* Konqueror HTML */
  -khtml-user-select: auto;
  /* Firefox */
  -moz-user-select: auto;
  /* Internet Explorer/Edge */
  -ms-user-select: auto;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  user-select: auto;
}

Image {
  border: none;
  display: block;
  transform: translateZ(0) scale(0.999999);
}

@font-face {
  font-family: "Billabong";
  src: local("Billabong"), url("./Billabong.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cookie-Regular";
  src: local("Cookie-Regular"), url("./Cookie-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.commentLink:hover {
  text-decoration: underline !important;
  color: "brown";
}

.profile-picture-uploader {
  text-align: center;
}

.file-input {
  display: none;
}

.preview-image {
  height: auto !important;
  width: auto !important;
  max-width: 200px !important;
  max-height: 200px !important;
  margin-top: 10px;
}
